import React, { useEffect, useState } from 'react';
import { loginUser, registerUser, verifyUser, setLoader } from '../../../Redux/Actions/action.users';
import Loader from '../../global/Loader/Loader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, setPersistence, browserLocalPersistence, signOut, updateProfile, sendPasswordResetEmail } from "firebase/auth";
import { auth, db, microsoftProvider } from '../../firebaseConfig';
import { doc, onSnapshot, updateDoc, setDoc, collection, serverTimestamp, getDoc, query, where, getDocs } from 'firebase/firestore';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
// import { documents } from './../../../Redux/Reducers/documents';

function Login({ users, loginUser, registerUser, loading, toast, verifyUser, setLoader }) {
  const navigate = useNavigate();
  const parameters = window.location.pathname == '/' && window.location.search == '?mode=forgot-password';
  const collectionRef = collection(db, 'users');
  const providerCollectionRef = collection(db,'providers');
  const [loginFormData, setLoginFormData] = useState({
    email: '',
    password: ''
  });
  const [loginError, setLoginError] = useState({
    email: '',
    password: ''
  })
  const [signUpFormData, setSignUpFormData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    email: ''
  });
  const [signUpError, setSignUpError] = useState({
    firstName: '',
    lastName: '',
    email: ''
  })

  const [isSignIn, setSignIn] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadingState = loading.length ? true : false;
    setIsLoading(loadingState);
  });

  const updateFormData = (event) => {
    const { name, value } = event.target;
    const regex = /[-’/`~!#*$@_%+=.,^&(){}[\]|;:"'”<>? \\]/g;
    if (name == 'firstName' || name == 'lastName') {
      if (value && regex.test(value)) { return }
    }
    if (isSignIn) {
      setLoginFormData((prev) => ({
        ...prev, [name]: value
      }))
    } else {
      setSignUpFormData((prev) => ({
        ...prev, [name]: value
      }))
    }
  }

  const validateForm = () => {
    if (isSignIn) {
  //----------------------------- LOGIN FIELDS VALIDATION --------------------------------------------------------------
      const { email, password } = loginFormData
      let error = {} ;
      if( !email || !password){
        error = {
          email: email?'':'Email field is required',
          password: password?'':'Password field is required',
        }
      }
      if(email){
        const isEmailValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
        if(!isEmailValid){
          error = {
            email:'Email is not valid.',
            password: error.password?error.password:''
          }
        }
      }

      if(Object.keys(error).length>0){
        setLoginError(error);
        return false;
      }else{
        return true;
      }
    } else {
      //----------------------------- SIGN UP FIELDS VALIDATION --------------------------------------------------------------
      const { firstName, lastName, email } = signUpFormData;
      let error = {};
      if(!firstName || !lastName || !email){
        error = {
          email: signUpFormData.email ? '' : 'Email cannot be empty',
          lastName: signUpFormData.lastName ? '' : 'Last Name cannot be empty',
          firstName: signUpFormData.firstName ? '' : 'First Name cannot be empty',
        }
      }

      if(email){
        const isEmailValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
        if(!isEmailValid){
          error = {
            email: 'Email is not valid',
          lastName: error.lastName?error.lastName:'',
          firstName: error.firstName?error.firstName:'',
          }
        }
      }

      if(Object.keys(error).length>0){
        setSignUpError(error);
        return false;
      }else{
        return true;
      }
    }
  }

  useEffect(() => {
    const unsub = onSnapshot(collectionRef, (querySnapshot) => {
      const items = [];
      querySnapshot.docs.forEach((doc) => {
        items.push(doc.data)
      });
    });
    return () => {
      unsub();
    }
  }, []);

  const signUpUser = async (e) => {
    e.preventDefault();
    if (!validateForm()) { return }
    setLoader('signupuser', true);
    const { firstName, lastName, email, companyName } = signUpFormData;
    const password = process.env.REACT_APP_CUSTOMER_DEFAULT_PASSWORD;
    const id = Date.now().toString();
    const newUser = {
      id: id,
      providerId: 'email/password',
      displayName: `${firstName} ${lastName}`,
      company_name: companyName,
      email: email,
      createdAt: serverTimestamp(),
      lastUpdate: serverTimestamp()
    };
    try {
      const useCredentials = await createUserWithEmailAndPassword(auth, email, password);
      await sendPasswordResetEmail(auth, email);
      updateProfile(auth.currentUser, { displayName: `${firstName} ${lastName}` }).then(() => { }).catch((error) => { console.log(error) });
      saveUserDetails(newUser);
      toast('success', `${firstName} ${lastName} registered successfully. An email has been sent to set your password.`);
      setLoader('signupuser', false);
    } catch (error) {
      setLoader('signupuser', false);
      console.log(error);
      if (error?.message?.includes('auth/network-request-failed')) {
        toast('error', 'Error connecting database. Please check your internet connection');
      }
      if (error?.message?.includes('auth/email-already-in-use')) {
        toast('error', 'User already exists. Please sign-in');
        console.log('Message', error.message)
        setLoader('signupuser', false);
      }
    }
  }

  const userLoginWithEmailandPassword = (e) => {
    e.preventDefault();
    const { email, password } = loginFormData;
    const isValid = validateForm();
    if (!isValid) return;
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential;
        // localStorage.setItem('user', JSON.stringify(user));
        // navigate('dashboard');
        verifyUser(user.user.stsTokenManager.accessToken).then(res => {
          if (res.status == 200) {
            localStorage.setItem('user', JSON.stringify(user));
            navigate('dashboard');
            return;
          }
          toast('error', 'Token not verified. Please try again with valid credentials');
        }).catch(error => {
          console.log(error);
        });
        // ...
      })
      .catch((error) => {
        const errorCode = error?.code;
        const errorMessage = error?.message;
        if (errorMessage && errorMessage.includes('auth/too-many-requests')) {
          console.log('Invalid Credentials')
          toast('error', 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.')
        }
        if (errorMessage && errorMessage.includes('auth/invalid-credential')) {
          toast('error', 'Invalid email/password(Please check your credentials) or User not found(Please Register)')
        }
      });
  }

  const signInWithMicrosoft = async (e) => {
    e.preventDefault();
    try {
      await setPersistence(auth, browserLocalPersistence);
      const res = await signInWithPopup(auth, microsoftProvider);
      const newUser = {
        id: res.user.uid,
        providerId: res.user.providerData[0].providerId,
        displayName: res.user.providerData[0].displayName,
        email: res.user.providerData[0].email,
        createdAt: serverTimestamp(),
        lastUpdate: serverTimestamp()
      };
      saveUserDetails(newUser);
      // localStorage.setItem('user', JSON.stringify(res));
      // navigate('dashboard');
      await verifyUser(res.user.stsTokenManager.accessToken).then(response => {
        if (response.status == 200) {
          localStorage.setItem('user', JSON.stringify(res));
          navigate('dashboard');
          return;
        }
        toast('error', 'Token not verified. Please try again with valid credentials');
      }).catch(error => {
        console.log(error);
      });
      
      //..
    } catch (error) {
      console.log('Error signing in:', error);
      const errorMessage = error?.message;
      if (errorMessage?.includes('auth/popup-closed-by-user')) {
        toast('error', 'Sign-in failed. Either user closed the popup before signing in or check your internet connection.')
      }
      if(errorMessage?.includes('auth/account-exists-with-different-credential')){
        toast('error','Sign-in failed. Same e-mail is associated with another login provider. Try signing in with another provider.')
      }
    }
  }

  const saveUserDetails = async (data) => {
    const docRef = doc(collectionRef, data.email);
    const document = await getDocs(query(collectionRef, where("email", "==", data.email)));
    // console.log(document.docs);
    if (document.docs.length) {
      return
    };
    const userRef = doc(collectionRef, data.id);
    const res = await setDoc(userRef, data);
    
    const providerData = {
      email: data.email,
      providerId: data.providerId
    }
    const providerRef = doc(providerCollectionRef,data.id)
    await setDoc(providerRef,providerData)
    changeSignInOut(true)
  }

  const changeSignInOut = (state) => {
    setSignIn(state);
    setSignUpError({
      firstName: '',
      lastName: '',
      email: ''
    });
    setLoginError({
      email: '',
      password: ''
    })
  }


  return (
    <>
      {isLoading ? <Loader /> : ''}
      <div className='login-page-background'>
        {!parameters ?
          <div className='login-form p-4 '>
            <form className=''>
              <img src='./images/logo.png' className='my-4 ' alt='logo' />
              <h5 className='fw-semibold'>{isSignIn ? "Sign In" : "Sign Up"}</h5>
              {isSignIn ?
                <div className='sign-in-form my-4'>
                  <div className="mb-3 mx-2">
                    <label htmlFor="email" className={`form-label mb-0 `}>Email{loginError.email ? '*' : ''}:</label>
                    <input className={`form-control ${loginError.email ? 'border-danger' : ''}`} type="text" placeholder="" name="email" id='email' onChange={(e) => { updateFormData(e) }} value={loginFormData.email} />
                    <p className='validation-error'>{loginError.email}</p>
                  </div>
                  <div className="mb-3 mx-2">
                    <label htmlFor="password" className={`form-label mb-0 `}>Password{loginError.password ? '*' : ''}:</label>
                    <input type="password" className={`form-control ${loginError.password ? 'border-danger' : ''}`} name="password" id="password" placeholder="" onChange={(e) => { updateFormData(e) }} value={loginFormData.password} />
                    <p className='validation-error'>{loginError.password}</p>
                  </div>
                  <Link to='?mode=forgot-password' className='forgot-password-link mb-2 mx-2'>Forgot Password?</Link>
                  <button className='btn btn-primary w-100 mt-2 mb-2' onClick={(e) => { userLoginWithEmailandPassword(e) }} id='btn-signin'>Sign In</button>
                  <button className='btn btn-primary w-100 mt-2 mb-2' onClick={(e) => { signInWithMicrosoft(e) }} >Sign In with Microsoft</button>
                </div>
                :
                <div className='sign-up-form my-4'>
                  <div className="row mx-2 my-3">
                    <div className="col-6 p-1">
                      <label htmlFor="firstname" className="form-label mb-0">First Name*</label>
                      <input className={`form-control ${signUpError.firstName == '' ? '' : 'border-danger'}`} type="text" placeholder="" name="firstName" id='firstname' onChange={(e) => { updateFormData(e) }} value={signUpFormData.firstName} required />
                      <p className='validation-error'>{signUpError.firstName}</p>
                    </div>
                    <div className="col-6 p-1">
                      <label htmlFor="lastname" className="form-label mb-0">Last Name*</label>
                      <input type="text" className={`form-control ${signUpError.lastName == '' ? '' : 'border-danger'}`} name="lastName" id="lastname" placeholder="" onChange={(e) => { updateFormData(e) }} value={signUpFormData.lastName} required />
                      <p className='validation-error'>{signUpError.lastName}</p>
                    </div>
                  </div>
                  <div className="mb-3 mx-2 my-3">
                    <label htmlFor="companyName" className="form-label mb-0">Company Name</label>
                    <input className={`form-control`} type="text" placeholder="" name="companyName" id='companyName' onChange={(e) => { updateFormData(e) }} value={signUpFormData.companyName} />
                  </div>
                  <div className="mb-3 mx-2 my-3">
                    <label htmlFor="email" className="form-label mb-0">Email*</label>
                    <input type="text" className={`form-control ${signUpError.email == '' ? '' : 'border-danger'}`} id="email" name="email" placeholder="" onChange={(e) => { updateFormData(e) }} value={signUpFormData.email} required />
                    <p className='validation-error'>{signUpError.email}</p>
                  </div>
                  <button className='btn btn-primary w-100 mt-2 mb-2' onClick={(e) => { signUpUser(e) }} id='btn-signup'>Sign Up</button>
                </div>
              }
              {
                isSignIn ?
                  <p className='mt-2 signup-link fw-semibold'>Don't have an account <Link onClick={() => changeSignInOut(false)}> Sign Up</Link></p> :
                  <p className='mt-2 signup-link fw-semibold'>Already have an account <Link onClick={() => changeSignInOut(true)}> Sign In</Link></p>
              }
            </form>
          </div> :
          <ForgotPassword toast={toast} />}
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loginUser,
    // registerUser,
    verifyUser,
    setLoader
  }, dispatch);
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    loading: state.loading.loading
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);