import React,{useState,useEffect} from 'react';
import './ProgressBarWithStatus.css';

function ProgressBarWithStatus({status,update,page}) {
  return (
    <div className={` w-100 ${page=='WebIngestion'? 'progress-bar-container': 'mt-4 mx-2'}`}> 
        <div className={`progress-bar-background ${page=='WebIngestion'? 'w-50':''}`}><div className={`progress-bar-${update?.percentage} `}></div></div> <div className='mx-2 progress-bar-percentage'></div>
        <div className={`d-flex justify-content-between align-items-baseline  ${page=='WebIngestion'?'w-50':''}`}>{status && status.map((state,index)=>{
          return <p key={index} className={`text-align-center word-wrap state ${update?.status?.filter(el=>Object.keys(el)==state?.key)[0][state?.key].toLowerCase()=='in progress'?'state-in-progress':''} ${update?.status?.filter(el=>Object.keys(el)==state?.key)[0][state?.key].toLowerCase() =="done" ?'state-completed':''}`}>{state?.name}</p>
        })}</div>
      <div>
      </div>
    </div>
  )
}

export default ProgressBarWithStatus;