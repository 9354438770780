import axios from "axios";

const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

export const getTalkToYourDataResponse = (question) => async(dispatch) =>  {
    let response;
    const config = {
        url: `${BASE_API_URL}/superwise/ask`,
        method:'GET',
        headers:{
            "access-control-allow-origin": "*",
            "Content-type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Methods": "*",
        },
        
        params:{
            message: question
        }
    }
    await axios(config).then((res)=>{
        response = res
    }).catch(error=>{
        console.log(error)
    })

    return response;
}